@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

@import '~tippy.js/dist/tippy.css';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();

  .button-row {
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 2rem;
  }

  .gap {
    gap: 1rem;
  }

  .gap-2 {
    gap: 10rem;
  }

  .modalClass {
    width: 80% !important;
  }

  .personality-title {
    font-weight: 700;
    font-size: 32px;

  }

  .infoModal {
    width: 95%;
    height: 90%;
  }

  .infoModal p {
    font-size: 22px !important;
    font-weight: 400 !important;
  }

  .legalModal {
    width: 95%;
    height: 90%;
    display: flex;
    justify-content: center;
  }

  // .legalModal p {
  //   font-size: 13pt !important;
  //   font-weight: 400 !important;
  // }

  .legalModal .bold {
    font-weight: bold !important;
  }

  .legalModal {

    ol,
    ul {
      @media (max-width: 768px) {
        padding-left: 1rem !important;
      }

      margin-bottom: 1rem !important;
    }
  }

  .modal-trigger {
    font-weight: 1000;
    color: $info !important;
    text-decoration: underline !important;

    &:hover {
      cursor: pointer;
      color: $purple !important;
    }
  }

  .infoModal span:first-of-type {
    font-weight: 500 !important;
    font-style: italic;
  }

  .personality-modal {
    width: 95%;
    height: 90%;

    & span {
      font-size: 17px !important;
      font-weight: 400 !important;
    }

    & span:first-of-type {
      font-weight: 500 !important;
      font-style: italic;
    }

    & span:last-of-type {
      color: $purple !important;
      font-weight: 700 !important;
    }

    nb-dialog-container {
      width: 100% !important;
    }
  }

  .vulnerability-big-modal {
    width: 95%;
    height: 90%;

    nb-dialog-container {
      width: 100% !important;
    }
  }

  .infoModal span:last-of-type {
    color: $purple !important;
    font-weight: 700 !important;
  }

  .cdk-overlay-container {
    // margin-top: $header-height;
    // height: calc(100% - $header-height);
    z-index: 1041;
  }

  .cdk-overlay-container:has(.cdk-overlay-backdrop) {
    z-index: 1040 !important;
    margin-top: $header-height;
    height: calc(100% - $header-height);
  }

  .cdk-overlay-wrapper {}

  .toastClass .icon-container {
    margin-right: 5px !important;
    margin-bottom: auto !important;

    nb-icon {
      font-size: 1.80rem !important;
      margin-bottom: auto !important;
    }
  }

  nb-layout-footer nav {
    height: 100%;
  }

  nb-dialog-container {
    height: 100% !important;
    display: block;
  }

  .semibold {
    font-weight: 600;
  }

  ngx-datatable {
    border-radius: 20px !important;
  }

  .custom-hover:hover {
    /* Añade un subrayado al hacer hover */
    text-decoration: underline;
    /* Especifica el color del subrayado */
    text-decoration-color: black;
    /* Ajusta el grosor del subrayado */
    text-decoration-thickness: 2px;
  }

  datatable-header-cell .datatable-header-cell-template-wrap,
  datatable-body-cell {
    font-size: 11pt !important;
    font-weight: 300 !important;
    align-self: center;
  }

  .available-status-size {
    width: 13px;
  }

  .gray-text {
    color: #A4A6A9;
  }

  .page-number {
    margin: 0 5px;
    cursor: pointer;
  }

  .custom-checkbox-icon {
    // display: none !important;
  }

  .scrollable-container {
    height: auto !important;
  }

  body {
    overflow: auto !important;
  }

  html {
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      /* Oculta la barra de scroll en Chrome/Safari */
    }
  }

  .active-page {
    font-weight: bold;
    color: #1B1B1B;
    font-weight: 800;
  }

  .pag-icon {
    cursor: pointer;
  }

  nb-chat .header {
    display: none;
  }

  @media (min-width: 768px) {
    .w-md-100 {
      width: 100%;
    }
  }

  nb-tabset nb-tab {
    padding-left: 0%;
    padding-right: 0%;

  }

  // Clases para deshabilitar el modo ventana si el dispositivo es demasiado grande
  nb-layout.window-mode .scrollable-container {
    max-width: 100% !important;
  }

  nb-layout.window-mode .layout nb-layout-header {
    max-width: 100% !important;
  }

  nb-layout.window-mode .layout nb-layout-header nav {
    max-width: 100% !important;
  }

  /* Asegúrate de que este CSS se carga en el contexto global o en un nivel donde pueda afectar a los diálogos */
  .cdk-overlay-pane:has(.custom-modal) {
    width: 95%;
    height: 100%;
    max-width: 95%;
    max-height: 90%;
    display: block;
  }

  .cdk-overlay-pane:has(nb-context-menu) {
    // top: 0 !important;
  }


  .cdk-overlay-pane:has(.storytelling-modal) {
    width: 99%;
    max-width: 99%;
    max-height: 99%;
    display: block;
  }


  .storytelling-modal {
    height: 99vh;
  }

  .eggTitle {
    font-weight: 500;
    size: 14px;
    color: $purple;
  }

  .vulnerability-score {
    color: $purple;
    font-weight: 700;
  }

  .button-personality {
    display: flex;
    justify-content: space-around;
    border: 2px solid #B8BDC3 !important;
    background-color: transparent !important;
    color: $info !important;
    font-size: large;
    font-weight: 400;
    transition: background-color 0.3s ease; // Añade una transición suave

    &:hover {
      background-color: rgba(185, 177, 243, 0.1) !important; // Cambia el color de fondo al hacer hover
    }
  }

  nb-accordion-item {
    border-radius: 20px;
  }
  .button-form {
    padding: 15px 30px 15px 30px !important;
    border-radius: 59px !important;
    gap: 1rem;
    line-height: 20.11px;
    font-weight: 700;
  }

  .meaning-action {
    color: $gray;

    &:hover {
      color: $purple;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .gif-modal {
    height: 40rem;
  }

  .chatbot-buttons {
    border: 1px solid rgba(184, 189, 195, 1) !important;
    border-radius: 30px !important;
    background-color: rgba(230, 230, 230, 0.3) !important;
    color: #000000 !important;
    height: 38px;
    white-space: normal;
  }

  .chatbot-buttons:hover {
    border: 1px solid rgba(185, 177, 243, 1) !important;
    background-color: rgba(185, 177, 243, 0.1) !important;
    color: #000000 !important;
  }

  .message-row {
    gap: 1rem;

    & input {
      background-color: nb-theme(chat-message-background) !important;
      border-radius: 20px !important;
      border-color: transparent !important;
      color: #222b45 !important;
    }

    & button {
      width: 50px;
      height: 50px;
      background-color: $success !important;
      border-radius: 50% !important;
      border-color: $success !important;

      & nb-icon {
        color: black;
      }
    }
  }

  nb-chat-form {
    padding: 1rem 8px 1rem 0 !important;
  }

  .cdk-overlay-pane {
    //top: 0 !important;
    /* Asegura que el menú contextual o el tooltip empiece desde la parte superior del overlay */
    margin-top: 0 !important;
    //transform: translateY(600px) !important; /* Desplazamiento vertical */
    /* Remueve cualquier margen superior que empuje hacia abajo el menú */
  }

  .close-gray {
    color: #B8B8B8 !important;
    cursor: pointer !important;
  }
  .hide-text-button {
    visibility: hidden;
}

.show-text-button {
    visibility: visible;
}

nb-popover:has(.popover-content) {
  background-color: $white !important;
  border-color: $info !important;
  color: $info !important;

  .first-vulnerability {
    font-size: large !important;
    font-weight: 700 !important;
  }
}
}