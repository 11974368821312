// @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '@nebular/theme/styles/themes';

// Color Variables
$info: #000000;
$success: #d5eb73;
$primary: #0370f0;
$danger: #ed98ba;
$white: #ffffff;
$gray: #9d9c9c;
$purple: #B9B1F3;


$chatbot-width: 1000px;
  
  $background-basic-color: 
  #f4f6fa;
  $header-height: nb-theme(header-height);
$nb-themes: nb-register-theme((layout-padding-top: 2.25rem,

      background-basic-color-3:	$background-basic-color,
      
      // Menu items classes
      menu-item-active-background-color: rgba(48, 110, 232, 0.04) ,
      menu-item-hover-background-color:	rgba(48, 110, 232, 0.04), 
      menu-item-icon-margin: 0 0.5rem 0 0,
      menu-item-divider-width: 10px,
      menu-item-border-radius: 6px,
      menu-item-divider-color: $white,
      // menu-item-padding: ,
      card-height-tiny: 13.5rem,
      card-height-small: 21.1875rem,
      card-height-medium: 28.875rem,
      card-height-large: 36.5625rem,
      card-height-giant: 44.25rem,
      card-margin-bottom: 1.875rem,
      card-header-with-select-padding-top: 0.5rem,
      card-header-with-select-padding-bottom: 0.5rem,
      card-border-radius: 20px,
      select-min-width: 6rem,

      slide-out-background: #f7f9fc,
      slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
      slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    ), default, default);

$nb-themes: nb-register-theme((
      // Variables globales para los textos
      tabset-tab-text-font-size: 13pt,
      // Checkbox
      checkbox-height: 1rem,
      checkbox-width: 1rem,
      checkbox-border-radius: 30px,
      checkbox-info-background-color: $background-basic-color,
      checkbox-basic-checked-background-color: $info,
      // Variables globales para los toast
      toastr-border-radius: 20px,
      toastr-text-font-size: 10pt,
      toastr-title-text-font-size: 13pt,
      toastr-basic-text-color: #000,
      toastr-icon-basic-color: #000,
      toastr-icon-basic-background-color: #000,
      toastr-primary-text-color: #000,
      toastr-icon-primary-color: #000,
      toastr-icon-primary-background-color: color-primary-500,
      toastr-success-text-color: #000,
      toastr-icon-success-color: #000,
      toastr-icon-success-background-color: color-success-500,
      toastr-info-text-color: #000,
      toastr-icon-info-color: #000,
      toastr-icon-info-background-color: color-info-500,
      toastr-warning-text-color: #000,
      toastr-icon-warning-color: #000,
      toastr-icon-warning-background-color: color-warning-500,
      toastr-danger-text-color: #000,
      toastr-icon-danger-color: #000,
      toastr-icon-danger-background-color: color-danger-500,
      toastr-control-text-color: #000,
      toastr-icon-control-color: #000,
      toastr-icon-control-background-color: #000,


      layout-header-height: 20px, // Cambia esto según lo que necesites

      // Variables globales para los botones
      button-filled-text-transform: 'none',
      button-outline-text-transform: 'none',
      button-ghost-text-transform: 'none',
      button-hero-text-transform: 'none',
      button-outline-border-width: 1pt,
      button-filled-info-hover-background-color: $gray,
      button-outline-info-hover-background-color: #141414,
      button-outline-info-background-color: $white,
      button-outline-info-hover-text-color: $white,
      button-outline-info-text-color: $info,
      button-outline-info-border-color: $info,
      button-outline-info-active-text-color: $white,
      button-outline-basic-border-color: $info,
      button-outline-basic-hover-background-color: $success,
      button-outline-basic-text-color: $info,
      button-filled-success-text-color: $info,

      // Variables globales para los cards
      card-text-line-height: 2.25rem,

      // Variables globales para los tags
      tag-filled-success-text-color: $info,
      tag-tiny-padding: 0.325rem 0.9375rem,
      // Variables globales para los headers
      text-heading-1-font-weight: 400,
      text-heading-2-font-weight: 400,
      text-heading-3-font-weight: 400,
      text-heading-4-font-weight: 400,
      text-heading-5-font-weight: 400,
      text-heading-6-font-weight: 400,
      // User medium
      user-small-name-text-font-size: 12pt,
      user-small-height: 30px,
      user-small-width: 30px,
      user-medium-height: 50px,
      user-medium-width: 50px,
      user-medium-initials-text-font-size:		16px,
      user-medium-initials-text-line-height:		24px,
      user-medium-name-text-font-size: 14px,
      user-medium-name-text-line-height:		20px	,
      user-medium-title-text-font-size:	0.75rem,
      user-initials-text-font-weight: 600,
      user-name-text-font-weight: 600,
      // Variables globales para tamaño headers
      text-heading-1-font-size: 35pt,
      text-heading-2-font-size: 25pt,
      text-heading-3-font-size: 15pt,
      text-heading-4-font-size: 15pt,
      text-heading-5-font-size: 15pt,
      text-heading-6-font-size: 15pt,

      // Variables globales para saltos de linea de cabeceras
      text-heading-1-line-height: 42pt,
      text-heading-2-line-height: 30pt,
      text-heading-3-line-height: 18pt,
      text-heading-4-line-height: 18pt,
      text-heading-5-line-height: 18pt,
      text-heading-6-line-height: 18pt,

      // Variables globales para texto dentro de los botones
      text-button-medium-font-size: 11pt,
      text-button-small-font-size: 10pt,
      text-button-font-weight: 500,
      // Chatbot globales
      chat-background-color: transparent,
      chat-shadow	: none,
      chat-message-reply-background-color: rgba(255, 255, 255, 0.3),
      chat-primary-background-color: rgba(255, 255, 255, 0.3),
      chat-message-background: rgba(255, 255, 255, 0.3),
      chat-message-text-color:  $info,
      chat-message-reply-text-color:  $info,
      
      // Variables para texto base
      text-paragraph-font-size: 11pt,

      // Variables globales para los forms
      text-label-font-weight: 400,
      text-label-font-size: 11pt,
      input-basic-placeholder-text-color: #8f9bb3,

      // Variables globales para actions
      actions-icon-color: $info,
      // Variables para inputs
      text-hint-color: $info,
      input-tiny-text-font-weight: 400,
      input-small-text-font-weight: 400,
      input-medium-text-font-weight: 400,
      input-large-text-font-weight: 400,
      input-giant-text-font-weight: 400,
      input-tiny-text-font-size: 11pt,
      input-small-text-font-size: 11pt,
      input-medium-text-font-size: 11pt,
      input-large-text-font-size: 11pt,
      input-giant-text-font-size: 11pt,
      input-border-width: 1px,
      input-border-style: solid,
      input-info-disabled-border-color: $gray,
      text-caption-font-size: 11pt,
      text-subtitle-font-size: 11pt,
      input-basic-background-color: $white,
      input-basic-border-color: $gray,

      // Definiciones para el color primario en grises (manteniendo la configuración anterior)
      color-primary-100: $primary,
      color-primary-200: $primary,
      color-primary-300: $primary,
      color-primary-400: $primary,
      color-primary-500: $primary,
      color-primary-600: $primary,
      color-primary-700: $primary,
      color-primary-800: $primary,
      color-primary-900: $primary,

      // Personalización para 'success' con tonos de verde
      color-success-100: $success,
      color-success-200: $success,
      color-success-300: $success,
      color-success-400: $success,
      color-success-500: $success,
      color-success-600: $success,
      color-success-700: $success,
      color-success-800: $success,
      color-success-900: $success,

      // Personalización para 'info' con tonos de azul
      color-info-100: $info,
      color-info-200: $info,
      color-info-300: $info,
      color-info-400: $info,
      color-info-500: $info,
      color-info-600: $info,
      color-info-700: $info,
      color-info-800: $info,
      color-info-900: $info,

      // Personalización para 'warning' con tonos de amarillo
      color-warning-100: #fff9db,
      color-warning-200: #fff3b8,
      color-warning-300: #ffec94,
      color-warning-400: #ffe777,
      color-warning-500: #ffdf5a,
      color-warning-600: #dbb142,
      color-warning-700: #b7842b,
      color-warning-800: #936014,
      color-warning-900: #7a4600,

      
      // Personalización para 'danger' con tonos de rojo
      color-danger-100: $danger,
      color-danger-200: $danger,
      color-danger-300: $danger,
      color-danger-400: $danger,
      color-danger-500: $danger,
      color-danger-600: $danger,
      color-danger-700: $danger,
      color-danger-800: $danger,
      color-danger-900: $danger,

     // Header classes
      header-shadow: transparent,
      header-padding: 0.25rem 24px,

      // Footer Classes
      footer-background-color	: transparent,
      footer-shadow: none,
      footer-divider-width: 0,
      
      // Popover classes
      popover-text-color: $white,
      popover-background-color: $info,
      popover-border-radius: 20px,
      // Tooltip classes
      tooltip-background-color: $info,
      // Sidebar Classes
      sidebar-background-color: $white,
      sidebar-padding: 1.25rem 1rem,
      // Layout classes
      layout-padding:		2.25rem 32px 0.75rem,
      layout-window-mode-min-width: 150px,
      layout-window-mode-padding-top: 0,
      // Select styles variables
      select-filled-info-background-color: $info,
      select-filled-info-border-color:  $info,
      select-filled-info-focus-background-color:  $info,
      select-filled-info-hover-background-color:  $info,
      select-filled-info-hover-border-color: $info ,
      option-selected-background-color: $info,
      option-selected-hover-background-color: $info,
      option-selected-focus-background-color: $info,

      // Tabs styles
      tabset-tab-active-text-color: $info,
      tabset-tab-active-underline-color: $info,
      tabset-tab-focus-text-color: $info ,
      tabset-tab-focus-underline-color: $info ,
      tabset-tab-hover-text-color: $info ,
      tabset-tab-hover-underline-color: $info,
      route-tabset-tab-hover-underline-colo: $info, 
      route-tabset-tab-hover-text-color: $info,
      route-tabset-tab-focus-underline-color: $info,
      route-tabset-tab-focus-text-color: $info,
      route-tabset-tab-active-underline-color: $info,
      route-tabset-tab-active-text-color: $info,

      // Accordion styles
      accordion-border-radius: 20px,
      accordion-shadow: 0,

      // Porgress Bar styles
      progress-bar-border-radius: 20px,
      progress-bar-success-text-color: $info,
      progress-bar-large-text-font-size: 0.5rem,
      progress-bar-large-height: 1.75rem
    ), default, default);
$nb-themes: nb-register-theme((layout-padding-top: 2.25rem,

      menu-item-icon-margin: 0 0.5rem 0 0,

      card-height-tiny: 13.5rem,
      card-height-small: 21.1875rem,
      card-height-medium: 28.875rem,
      card-height-large: 36.5625rem,
      card-height-giant: 44.25rem,
      card-margin-bottom: 1.875rem,
      card-header-with-select-padding-top: 0.5rem,
      card-header-with-select-padding-bottom: 0.5rem,

      select-min-width: 6rem,

      slide-out-background: #252547,
      slide-out-shadow-color: 2px 0 3px #29157a,
      slide-out-shadow-color-rtl: -2px 0 3px #29157a,
    ), cosmic, cosmic);

$nb-themes: nb-register-theme((layout-padding-top: 2.25rem,

      menu-item-icon-margin: 0 0.5rem 0 0,

      card-height-tiny: 13.5rem,
      card-height-small: 21.1875rem,
      card-height-medium: 28.875rem,
      card-height-large: 36.5625rem,
      card-height-giant: 44.25rem,
      card-margin-bottom: 1.875rem,
      card-header-with-select-padding-top: 0.5rem,
      card-header-with-select-padding-bottom: 0.5rem,

      select-min-width: 6rem,

      slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%),
      slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
      slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    ), corporate, corporate);

$nb-themes: nb-register-theme((layout-padding-top: 2.25rem,

      menu-item-icon-margin: 0 0.5rem 0 0,

      card-height-tiny: 13.5rem,
      card-height-small: 21.1875rem,
      card-height-medium: 28.875rem,
      card-height-large: 36.5625rem,
      card-height-giant: 44.25rem,
      card-margin-bottom: 1.875rem,
      card-header-with-select-padding-top: 0.5rem,
      card-header-with-select-padding-bottom: 0.5rem,
      select-min-width: 6rem,

      slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
      slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
      slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    ), dark, dark);